<template>
    <v-data-table
        :headers="headers"
        :items="entities"
        class="elevation-1"
        :footer-props="{ 'itemsPerPageOptions': [50, 100, 200, -1] }"
    >
        <template v-slot:top>
            <v-toolbar flat>
                <v-toolbar-title>{{ $t('src\\views\\bookings\\list.9326') }}</v-toolbar-title>
                <v-spacer />
            </v-toolbar>
        </template>
        <template v-slot:[`item.createdAt`]="{ item }">
            {{ item.createdAt | moment('DD.MM.YYYY HH:mm') }}
        </template>
        <template v-slot:[`item.trip`]="{ item }">
            {{ item.trip ? item.trip.name : '' }}
        </template>
        <template v-slot:[`item.organizer`]="{ item }">
            {{ item.trip && item.trip.user ? item.trip.user.firstname : '' }} {{ item.trip && item.trip.user ? item.trip.user.lastname : '' }}
        </template>
        <template v-slot:[`item.user`]="{ item }">
            {{ item.user ? item.user.firstname : '' }} {{ item.user ? item.user.lastname : '' }}
        </template>
        <template v-slot:[`item.dates`]="{ item }">
            {{ item.start | moment('DD.MM.YY') }} - {{ item.finish | moment('DD.MM.YY') }}
        </template>
        <template v-slot:[`item.price`]="{ item }">
            {{ item.price }} <span v-html="currency(item.currency)"/>
        </template>
        <template v-slot:[`item.status`]="{ item }">
            {{ $t(BOOKING_STATUS_TITLE[item.status]) }}
            <v-menu v-if="user.adminAccess && user.adminAccess.trips >= ACCESS.WRITE">
                <template v-slot:activator="{ attrs, on }">
                    <v-icon
                        color="primary"
                        small
                        class="ml-2"
                        v-bind="attrs"
                        v-on="on"
                    >mdi-pencil</v-icon>
                </template>
                <v-list>
                    <v-list-item
                        v-for="(status, index) in BOOKING_STATUS_TITLE"
                        :key="index"
                        @click="setStatus(item._id, index)"
                    >
                        <v-list-item-title>{{ $t(status) }}</v-list-item-title>
                    </v-list-item>
                </v-list>
            </v-menu>
        </template>
        <template v-slot:[`item.edit`]="{ item }">
            <v-icon color="primary" @click="edit(item._id)">mdi-eye</v-icon>
        </template>
    </v-data-table>
</template>

<script>
    import store from '@/store';
    import { mapState } from 'vuex';
    import { BOOKING_STATUS_TITLE } from '@/vars';
    import { ACCESS } from '@/vars';

    export default {
        name: 'BookingsList',
        components: {
        },
        data: () => ({
            ACCESS,
            BOOKING_STATUS_TITLE,
            headers: [],
        }),
        computed: {
            ...mapState('auth', ['user']),
            ...mapState('bookings', ['entities']),
        },
        async mounted() {
            await store.dispatch('auth/fetch');
            await store.dispatch('bookings/fetch', {});
            this.headers = [
                { text: this.$t('src\\views\\bookings\\list.createdAt'), value: 'createdAt' },
                { text: this.$t('src\\views\\bookings\\list.trip'), value: 'trip' },
                { text: this.$t('src\\views\\bookings\\list.organizer'), value: 'organizer' },
                { text: this.$t('src\\views\\bookings\\list.user'), value: 'user' },
                { text: this.$t('src\\views\\bookings\\list.dates'), value: 'dates' },
                { text: this.$t('src\\views\\bookings\\list.price'), value: 'price' },
                { text: this.$t('src\\views\\bookings\\list.status'), value: 'status' },
                { text: '', align: 'right', value: 'edit' }
            ]
        },
        methods: {
            currency (value) {
                return {
                    'EUR': '&#8364;', 
                    'USD': '&#36;',
                    'RUB': '&#8381;',
                }[value]
            },
            async edit(id) {
                await this.$router.push({ name: 'bookings-detail', params: { id } });
            },
            async remove(id) {
                await store.dispatch('bookings/delete', { id });
                await store.dispatch('bookings/fetch', {});
            },
            async setStatus(id, status) {
                await store.dispatch('bookings/setStatus', { id, status });
                await store.dispatch('bookings/fetch', {});
            }
        }
    }
</script>
